export const getSubscriptionList = ()=>{
    return [
        {
            sub_plan_short_name:SUBSCRIPTION_PLAN_TYPE.BASIC,
            sub_plan_id:"1234",
            sub_plan_amt:'29',
            sub_plan_name:"Sales Performance",
            sub_plan_dec:"Simple sales tracking and team performance",
            sub_plan_pricing_desc:"$29 /mo per user",
        },
        {
            sub_plan_short_name:SUBSCRIPTION_PLAN_TYPE.PREMIUM, //ENUM
            sub_plan_id:"1234",
            sub_plan_amt:'39',
            sub_plan_name:"Complete CRM",
            sub_plan_dec:"All of Goals.com, turn-key and ready to go",
            sub_plan_pricing_desc:"$39 /mo per user",
        }
        
    ]
    
}

export const SUBSCRIPTION_PLAN_TYPE = {
    BASIC:"basic",
    PREMIUM:"premium"
}