import NotificationActionTypes from './notification.type';

const INITIAL_STATE = {
  count: 0,
  discussion: false,
  highlights: false,
  helpChat: false,
};

const notificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NotificationActionTypes.SET_NOTIFICATION_COUNT:
      return { ...state, count: action.payload };
    case NotificationActionTypes.SET_NOTIFICATION_DISCUSSION:
      return { ...state, discussion: action.payload };
    case NotificationActionTypes.SET_NOTIFICATION_HIGHLIGHT:
      return { ...state, highlights: action.payload };
    case NotificationActionTypes.SET_NOTIFICATION_HELPCHAT:
      return { ...state, helpChat: action.payload };
    default:
      return state;
  }
};

export default notificationReducer;
