import { getCurrentUser } from '../../services/authentication.services';

import { store } from '../../redux/store';
import { setCurrentUser } from '../../redux/user/user.action';
import { setCompany } from '../../redux/company/company.action';

export const setUserData = async () => {
  const res = await getCurrentUser();
  if (res?.data?.response_type === 'success') {
    store.dispatch(setCurrentUser(res?.data?.data?.user));
    store.dispatch(setCompany(res?.data?.data?.company));
    return true;
  } else {
    return false;
  }
};
