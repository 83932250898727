import { createSelector } from 'reselect';
import { businessTypeObj } from '../../utils/constants/setting';
import { SUBSCRIPTION_PLAN_TYPE } from '../../utils/helper/subscription';

const selectCompanyFromStore = (state) => state.company;

export const selectCompany = createSelector([selectCompanyFromStore], (company) => company);

export const selectCompanyOfferName = createSelector(
  [selectCompanyFromStore],
  (company) => company?.offerName || 'Products/Services'
);

const selectstageNamesFromStore = (state) => state?.company?.SalesStageNames;
export const selectCompanySalesStageNames = createSelector([selectstageNamesFromStore], (stageNames) => ({
  ...(stageNames ? stageNames : {}),
  customers: stageNames?.customers || 'Customers',
  leads: stageNames?.leads || 'Leads',
  opportunities: stageNames?.opportunities || 'Opportunities',
  proposals: stageNames?.proposals || 'Proposals',
}));

export const selectBusinessTypeIsB2C = createSelector(
  [selectCompanyFromStore],
  (company) => company?.businessType === businessTypeObj.b2c
);

export const selectIsSubscriptionPlanBasicPlan = createSelector(
  [selectCompanyFromStore],
  (company) => company?.subscriptionPlanType === SUBSCRIPTION_PLAN_TYPE.BASIC
);
