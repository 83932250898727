import SearchActionTypes from './search.type';

const INITIAL_STATE = { value: null, load: false };

const searchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SearchActionTypes.SET_SEARCH_ITEM:
      return { ...state, value: action.payload };
    case SearchActionTypes.SET_LOAD:
      return { ...state, load: action.payload };
    default:
      return state;
  }
};

export default searchReducer;
