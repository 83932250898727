import warningModelActionTypes from './warningModel.type';

export const showWarningModel = (data) => ({
  type: warningModelActionTypes.SHOW_MODEL,
  payload: data,
});
export const closeWarningModel = () => ({
  type: warningModelActionTypes.CLOSE_MODEL,
  payload: '',
});
