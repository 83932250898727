import { useDispatch, useSelector } from 'react-redux';
import WarningIcon from '../../images/warning_icon.png';
import Processor from '../processor/processor';
import { selectWarningModelProps } from '../../redux/warningModel/warningModel.selector';
import { closeWarningModel } from '../../redux/warningModel/warningModel.action';
import { useState } from 'react';

const WarningPopupGlobal = () => {
  const {
    text,
    onConfirm,
    ConfirmButtonText = 'Ok',
    CloseButtonText = 'Cancel',
    showCancelButton = true,
    showConfirmButton = true,
  } = useSelector(selectWarningModelProps);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closeWarningModel());
  };

  const handelOnConfirm = async () => {
    setLoading(true);
    if (onConfirm) {
      await onConfirm();
    }
    onClose();
    setLoading(false);
  };

  return (
    <div className="chat_delete_modal cs_moda_main_wrapper show">
      <span className="modal_overlay"></span>
      <div className="inner_box">
        <button className="modal-close-btn" onClick={onClose}></button>
        <div className="chat_deleteMI_wrapper">
          <img src={WarningIcon} alt="" />
          <p className="text">{text}</p>
          <div className="cs_form_submit_row">
            <div className={`submit-btn-with-process ${loading ? 'loader_active' : ''}`}>
              {loading && <Processor />}
              {showConfirmButton && (
                <input
                  className="save_btn"
                  type="submit"
                  value={ConfirmButtonText}
                  disabled={loading}
                  onClick={handelOnConfirm}
                />
              )}
            </div>
            {showCancelButton && (
              <div className="cancel-btn" onClick={() => onClose()}>
                {CloseButtonText}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarningPopupGlobal;
