import warningModelActionTypes from './warningModel.type';

const INITIAL_STATE = {
  show: false,
  text: '',
  onConfirm: null,
  ConfirmButtonText: 'Ok',
  CloseButtonText: 'Cancel',
  showCancelButton: true,
  showConfirmButton: true,
};

const warningModelReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case warningModelActionTypes.SHOW_MODEL:
      return { show: true, ...action.payload };
    case warningModelActionTypes.CLOSE_MODEL:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default warningModelReducer;
