import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './css/email.css';
import App from './App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { store, persistor } from './redux/store';
import reportWebVitals from './reportWebVitals';
import SetupAxios from './axios/config';

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker
//     .register('../../webpushr-sw.js')
//     .then(function (registration) {
//       console.log('Registration successful, scope is:', registration.scope);
//     })
//     .catch(function (error) {
//       console.log('Service worker registration failed, error:', error);
//     });
// }

SetupAxios(store);
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
